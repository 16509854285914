import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import GroupIcon from '@mui/icons-material/Group';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SettingsIcon from '@mui/icons-material/Settings';
import usePermissions from 'shared/hooks/usePermissions';
// import HomeIcon from '@mui/icons-material/Home';

const formatCount = (count = 0) => (Number(count) > 99 ? '99+' : count);
export default ({ woCount = 0 }) => {
  const { technicianCommonPermission } = usePermissions();
  return [
    // {
    //   url: 'home',
    //   title: 'Home',
    //   icon: <HomeIcon />,
    //   permission: technicianCommonPermission,
    // },
    {
      url: 'technician/marketplace',
      title: 'Marketplace Information',
      icon: <LocalMallIcon />,
      permission: technicianCommonPermission,
    },
    {
      url: 'technician/my-customers',
      title: 'My Customers',
      icon: <FolderSharedIcon />,
      permission: technicianCommonPermission,
      children: [
        {
          url: 'technician/my-customers/facilities',
          title: 'Facilities',
          permission: technicianCommonPermission,
        },
        {
          url: 'technician/my-customers/devices',
          title: 'Devices',
          permission: technicianCommonPermission,
        },
      ],
    },
    {
      url: 'work-orders',
      title: 'Service Requests',
      icon: <AssignmentIcon />,
      badge: formatCount(woCount),
      permission: technicianCommonPermission,
    },
    {
      url: 'technician/my-team',
      title: 'My Team',
      icon: <GroupIcon />,
      permission: technicianCommonPermission,
    },
    {
      url: 'settings',
      title: 'Settings',
      icon: <SettingsIcon />,
      permission: technicianCommonPermission,

      children: [
        {
          url: 'settings/account',
          title: 'Account',
          permission: technicianCommonPermission,
        },
        {
          url: 'settings/business',
          title: 'Business',
          permission: technicianCommonPermission,
        },
      ],
    },
  ];
};
